<template>
  <div class="main-body">
    <div class="page-attention">
      <p>
        支持端口：微信公众号、微信小程序、扫码支付、H5、Android APP、IOS APP
      </p>
    </div>
    <a-divider />
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="商户号" extra="[MCHID]微信支付商户号">
        <a-input
          v-model:value="formState.wechatpay.mch_id"
          placeholder="请输入商户号"
        />
      </a-form-item>
      <a-form-item label="支付签名串API密钥">
        <a-textarea
          v-model:value="formState.wechatpay.pay_signkey"
          placeholder="请输入支付签名串API密钥"
        />
      </a-form-item>
      <a-form-item
        label="支付证书cert"
        :extra="formState.wechatpay.apiclient_cert"
      >
        <UpFile v-model="formState.wechatpay.apiclient_cert" />
      </a-form-item>
      <a-form-item
        label="支付证书key"
        :extra="formState.wechatpay.apiclient_key"
      >
        <UpFile v-model="formState.wechatpay.apiclient_key" />
      </a-form-item>
      <a-form-item label="是否启用支付">
        <a-switch v-model:checked="formState.wechatpay.pay_status" />
      </a-form-item>
      <a-form-item label="是否启用退款">
        <a-switch v-model:checked="formState.wechatpay.refund_status" />
      </a-form-item>
      <a-form-item label="是否启用转账">
        <a-switch v-model:checked="formState.wechatpay.transfer_status" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import UpFile from "@/components/UpFile";
import * as Api from "@/addons/payment/api";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    UpFile,
  },
  setup() {
    const formState = reactive({
      wechatpay: {
        mch_id: "",
        pay_signkey: "",
        apiclient_cert: "",
        apiclient_key: "",
        pay_status: true,
        refund_status: true,
        transfer_status: true,
      },
    });

    const onSubmit = () => {
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_setting({ key: "wechatpay" }).then((res) => {
        formState.wechatpay.mch_id = res.data.mch_id;
        formState.wechatpay.pay_signkey = res.data.pay_signkey;
        formState.wechatpay.apiclient_cert = res.data.apiclient_cert;
        formState.wechatpay.apiclient_key = res.data.apiclient_key;
        formState.wechatpay.pay_status = res.data.pay_status;
        formState.wechatpay.refund_status = res.data.refund_status;
        formState.wechatpay.transfer_status = res.data.transfer_status;
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>